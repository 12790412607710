<template>
    <div>
        <div class="row mt-2">
            <div class="col-5 pt-3 pl-lg-5">
                <p>ALABO EXCEL</p>
            </div>
            <div class="col-7 p-3 text-right pr-lg-5">
               <a href="https://twitter.com/alaboExcel">
              <i class="bi bi-twitter"></i>              
              </a>
              <a href="mailto:iamalaboexcel@gmail.com">
                <i class="bi bi-envelope-fill"></i>
              </a>
              <a href="https://www.facebook.com/alabo.kurotams">
              <i class="bi bi-facebook"></i>
              </a>
              <a href="https://github.com/alabo-excel">
              <i class="bi bi-github"></i>
              </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    i{
        margin: 10px;
    }
    .row{
          background-color:#f8f9fa;
    }
    a{
  color: #16181a;
}
</style>